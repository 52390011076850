<template>
  <div>
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="small"
      label-width="150px"
    >
      <el-form-item label="企业名称" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入企业名称企业名称"
          clearable
          :style="{ width: '100%' }"
          :disabled="tijBtnShow == false"
        >
        </el-input>
      </el-form-item>
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="24">
          <el-form-item
            label-width="150px"
            label="统一社会信用代码"
            prop="shxydm"
          >
            <el-input
              v-model="formData.shxydm"
              placeholder="请输入统一社会信用代码"
              clearable
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="8">
          <el-form-item label="法人代表" prop="frdb">
            <el-input
              v-model="formData.frdb"
              placeholder="请输入法人代表"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="法人身份证" prop="frsfz">
            <el-input
              v-model="formData.frsfz"
              placeholder="请输入法人身份证"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="150px" label="注册时间" prop="zcsj">
            <el-date-picker
              v-model="formData.zcsj"
              type="datetime"
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
              placeholder="请输入注册时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="8">
          <el-form-item label="注册机关" prop="zcjg">
            <el-input
              v-model="formData.zcjg"
              placeholder="请输入注册机关"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="150px" label="注册资本(万)" prop="zczb">
            <el-input
              v-model="formData.zczb"
              placeholder="请输入注册资本(万)"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="登记状态" prop="djzt">
            <el-select
              v-model="formData.djzt"
              placeholder="请输入登记状态"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in djztOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="tijBtnShow == false"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="8">
          <el-form-item label-width="150px" label="企业网址" prop="qywz">
            <el-input
              v-model="formData.qywz"
              placeholder="请输入企业网址"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="150px" label="企业传真" prop="qycz">
            <el-input
              v-model="formData.qycz"
              placeholder="请输入企业传真"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业邮箱" prop="qyyx">
            <el-input
              v-model="formData.qyyx"
              placeholder="请输入企业邮箱"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row type="flex" justify="start" align="middle">
        <el-form-item label="企业性质" prop="qyxz">
          <FormSelect
            v-model="formData.qyxz"
            size="small"
            :disabled="!tijBtnShow"
            :clearable="false"
            :options="getDictList('sys_regType')"
            valueKey="key"
          ></FormSelect>
        </el-form-item>
        <el-form-item label="产业类型" prop="cylx">
          <el-select
            v-model="formData.cylx"
            placeholder="请选择产业类型"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
            <el-option
              v-for="(item, index) in cylxOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row> -->
      <el-row type="flex" justify="start" align="middle">
        <!-- <el-form-item label="国民经济分类" prop="gwjjfl">
          <el-cascader
            v-model="formData.gwjjfl"
            :options="gwjjflOptions"
            :props="gwjjflProps"
            :style="{ width: '100%' }"
            placeholder="请选择国民经济分类"
            clearable
            :disabled="tijBtnShow == false"
          ></el-cascader>
        </el-form-item> -->
      </el-row>
      <!-- <el-form-item
                label="融资渠道"
                prop="rzqd"
            >
                <el-checkbox-group
                    v-model="formData.rzqd"
                    size="medium"
                    :disabled="!tijBtnShow"
                >
                    <el-checkbox
                        v-for="(item) in rzqdOptions"
                        :key="item.value"
                        :label="item.value"
                    >{{ item.label }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item
                label-width="150px"
                label="企业异动"
                prop="qyyd"
            >
                <el-checkbox-group
                    v-model="formData.qyyd"
                    size="medium"
                >
                    <el-checkbox
                        v-for="(item, index) in qyydOptions"
                        :key="index"
                        :label="item.value"
                        :disabled="tijBtnShow == false"
                    >{{ item.label }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item> -->
      <el-form-item label="注册地址" prop="zcdz">
        <el-input
          v-model="formData.zcdz"
          placeholder="请输入注册地址"
          clearable
          :style="{ width: '100%' }"
          :disabled="tijBtnShow == false"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item
                label="总部地址"
                prop="zbdz"
            >
                <el-input
                    v-model="formData.zbdz"
                    placeholder="请输入总部地址"
                    clearable
                    :style="{ width: '100%' }"
                    :disabled="tijBtnShow == false"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="办公楼宇"
                prop="bgly"
            >
                <el-input
                    v-model="formData.bgly"
                    placeholder="请输入办公楼宇"
                    clearable
                    :style="{ width: '100%' }"
                    :disabled="tijBtnShow == false"
                ></el-input>
            </el-form-item> -->
      <!-- <el-row type="flex" justify="start" align="middle" :style="{ width: '100%' }"> -->
      <el-form-item label-width="150px" label="企业归属地（园区）" prop="xzqh1">
        <el-cascader
          v-model="formData.xzqh1"
          :options="xzqh1Options"
          :props="xzqh1Props"
          :style="{ width: '100%' }"
          placeholder="请选择企业归属地（园区）"
          clearable
          :disabled="tijBtnShow == false"
        ></el-cascader>
      </el-form-item>
      <fieldset class="b-fieldset">
        <legend class="b-fieldset-legend">
          政策推送主要判定标准，请仔细填写
        </legend>
        <el-row>
          <el-col :span="8">
            <el-form-item label="行业类别">
              <el-select
                v-model="form.sysCategory"
                multiple
                collapse-tags
                placeholder="请选择行业类别"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in hyleList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业规模">
              <el-select
                v-model="form.enterpriseSize"
                placeholder="请选择企业规模"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in qygmList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业属性">
              <el-select
                v-model="form.enterpriseAttributes"
                placeholder="请选择企业属性"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in qysxList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="成长周期">
              <el-select
                v-model="form.growthCycle"
                placeholder="请选择成长周期"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in czzqList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产业类别">
              <el-select
                v-model="form.sysEnterType"
                placeholder="请选择产业类别"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in cyleList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上一年度企业所得税">
              <el-input
                v-model="form.lastIncomeTax"
                placeholder="上一年度企业所得税"
                :disabled="tijBtnShow == false"
              >
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
              <el-form-item label="注册要求">
                <el-input
                  v-model="form.requireRegist"
                  placeholder="请输入注册要求"
                  :disabled="tijBtnShow == false"
                />
              </el-form-item>
            </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="纳税地">
              <el-input
                v-model="form.paymentPlace"
                placeholder="请输入纳税地"
                :disabled="tijBtnShow == false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业注册类型">
              <el-select
                v-model="form.sysEnterpriseRegistertype"
                placeholder="请选择企业注册类型"
                style="width: 100%"
                :disabled="tijBtnShow == false"
              >
                <el-option
                  v-for="dict in qyzclxList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主营业务收入">
              <el-input
                v-model="form.mainbusinessIncome"
                placeholder="请输入主营业务收入"
                :disabled="tijBtnShow == false"
                type="number"
              >
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" align="middle"> </el-row>
      </fieldset>

      <el-form-item label="经营范围" prop="jyfw">
        <el-input
          v-model="formData.jyfw"
          type="textarea"
          placeholder="请输入经营范围"
          :maxlength="1000"
          :autosize="{ minRows: 4, maxRows: 4 }"
          :style="{ width: '100%' }"
          :disabled="tijBtnShow == false"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业简介" prop="qyjj">
        <el-input
          v-model="formData.qyjj"
          type="textarea"
          placeholder="请输入企业简介"
          :maxlength="1500"
          :autosize="{ minRows: 4, maxRows: 10 }"
          :style="{ width: '100%' }"
          :disabled="tijBtnShow == false"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="" v-show="tijBtnShow">
         <span style="color:red">编辑后请点击保存按钮！</span>
       </el-form-item> -->
      <el-form-item size="large">
        <!-- <el-button
          v-show="tijBtnShow == true"
          type="primary"
          @click="submitForm"
          >{{ addUpStatus == 0 ? "保存" : "修改" }}</el-button
        > -->
        <el-button
          v-show="tijBtnShow == true"
          type="primary"
          @click="submitForm"
          >保存</el-button
        >
        <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
          tijBtnShow ? "取消" : "编辑"
        }}</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { FormSelect } from "@/components/form/index.js";
export default {
  components: {
    // FormSelect,
  },
  props: {
    isfromLogin:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      /* 提交按钮 */
      tijBtnShow: true,
      /* tabs相关 */
      editableTabsValue: "2",
      editableTabs: [
        {
          title: "Tab 1",
          name: "1",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "2",
          content: "Tab 2 content",
        },
      ],
      tabIndex: 2,
      /* 是 新增 0 还是 修改 1 状态 */
      addUpStatus: 0,
      /* 表单数据 */
      // formData: {
      //   name: "",
      //   shxydm: "",
      //   frdb: "王威",
      //   frsfz: "430623198901018888",
      //   zcsj: "",
      //   zcjg: "德阳市场监督管理局",
      //   zczb: "157497",
      //   djzt: "1",
      //   qyxz: "2",
      //   cylx: "7",
      //   qywz: "http://www.sztaiji.com",
      //   gwjjfl: "A",
      //   qycz: "0755-86309810",
      //   qyyx: "284888888@qq.com",
      //   rzqd: ["1000", "1003"],
      //   qyyd: ["in_normal"],
      //   zcdz: "德阳新一代产业园3栋1402",
      //   zbdz: "136号德阳新一代产业园3栋1402",
      //   bgly: "新一代产业园3栋1402",
      //   xzqh1: [1, 11, 111],
      //   xzqh2: "1", // ??
      //   xzqh3: "1", // ??
      //   xzqhdm: "",
      //   zczbdw: "840", // ??
      //   webEid: "11111", // ??
      //   sspq: 1,
      //   ssjd: 1,
      //   bgmj: "1678",
      //   zlyfmj: "1687",
      //   ggyfmj: "0",
      //   zjyfmj: "0",
      //   zkyxrs: "9",
      //   ssjysrs: "9",
      //   bsrs: "12",
      //   bkrs: "375",
      //   zkrs: "140",
      //   // jsrs: "450",
      //   // zczrs: "533",
      //   fzjgsl: "0",
      //   hwfzjgsl: "0",
      //   hwyxjgsl: "0",
      //   sfjldzz: "1",
      //   fmzls: "2",
      //   ssqk: "listed",
      //   ssbk: "02",
      //   ssdm: "3213423",
      //   jyfw: "要点1：所属板块 MSCI中国 MSCI中盘 创业板综 创业成份 富时概念 广东板块 华为概念 苹果概念 融资融券 深成500 深股通 德阳特区 石墨烯 输配电气 特斯拉 无线充电 无线耳机 小米概念 新能源车 虚拟现实 证金持股 中证500 纾困概念 锂电池要点2：经营范围 电池、充电器、精密模具、精密注塑、仪器仪表、工业设备、自动化设备及产线的研发、制造、销售;电子产品的研发、制造、销售;动力电池系统、储能电池及储能系统的研发、制造、销售;软件开发及销售;锂离子电池、蓄电池、蓄电池组的实验室检测、技术咨询服务;医疗器械;医疗安全系列产品;工业防护用品;劳动防护用品等研发、生产、销售;移动基站、通信设备、电子触控笔、家电类、音箱类、灯具类、转换器类、电器开关的研发、生产和销售;玩具生产及销售;锂离子电池材料、高性能膜材料、电解液材料的研发、生产、销售;兴办实业(具体项目另行申报),国内商业、物资供销业,货物及技术进出口;物业租赁;普通货运;(以上项目均不含法律、行政法规、国务院决定规定需前置审批及禁止项目)。",
      //   qyjj: "欣旺达电子股份有限公司(以下简称“本公司”)前身为“德阳欣旺达电子有限公司”,成立于1997年12月9日，系在德阳工商行政管理局登记注册的有限责任公司，注册号为4403012038699，注册资本为人民币100万元。其中：王明旺出资78万元，持有公司78%的股权；王威出资22万元，持有公司22%的股权。2008年7月，公司股东王明旺、王威（以下简称“甲方”）与湖南邦普循环科技有限公司、德阳市欣明达投资有限公司(以下简称“欣明达投资”)、肖光昱、项海",
      // },
      formData: {
        name: "",
        shxydm: "",
        frdb: "",
        frsfz: "",
        zcsj: "",
        zcjg: "",
        zczb: "",
        djzt: "",
        qyxz: "",
        cylx: "",
        qywz: "",
        gwjjfl: "",
        qycz: "",
        qyyx: "",
        rzqd: [],
        qyyd: [],
        zcdz: "",
        zbdz: "",
        bgly: "",
        xzqh1: [],
        xzqh2: "1", // ??
        xzqh3: "1", // ??
        xzqhdm: "",
        zczbdw: "", // ??
        webEid: "", // ??
        sspq: 1,
        ssjd: 1,
        bgmj: "",
        zlyfmj: "",
        ggyfmj: "",
        zjyfmj: "",
        zkyxrs: "",
        ssjysrs: "",
        bsrs: "",
        bkrs: "",
        zkrs: "",
        // jsrs: "450",
        // zczrs: "533",
        fzjgsl: "",
        hwfzjgsl: "",
        hwyxjgsl: "",
        sfjldzz: "",
        fmzls: "",
        ssqk: "",
        ssbk: "",
        ssdm: "",
        jyfw: "",
        qyjj: "",
      },
      form: {
        sysCategory: "",
        enterpriseSize: "",
        enterpriseAttributes: "",
        growthCycle: "",
        sysEnterType: "",
        requireRegist: "",
        paymentPlace: "",
        sysEnterpriseRegistertype: "",
        mainbusinessIncome: "",
        lastIncomeTax: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入企业名称企业名称",
            trigger: "blur",
          },
        ],
        shxydm: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "blur",
          },
        ],
        frdb: [
          {
            required: true,
            message: "请输入法人代表",
            trigger: "blur",
          },
        ],
        frsfz: [
          {
            required: true,
            pattern:
              /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: "请输入正确的身份证号",
            trigger: "blur",
          },
        ],
        zcsj: [
          {
            required: true,
            message: "请选择注册时间",
            trigger: "blur",
          },
        ],
        zcjg: [
          {
            required: true,
            message: "请输入注册机关",
            trigger: "blur",
          },
        ],
        zczb: [
          {
            required: true,
            message: "请输入注册资本(万)",
            trigger: "blur",
          },
        ],
        gwjjfl: [
          {
            required: true,
            message: "请选择国民经济分类",
            trigger: "blur",
          },
        ],
        djzt: [
          {
            required: true,
            message: "请输入登记状态",
            trigger: "change",
          },
        ],
        qyxz: [
          {
            required: true,
            message: "请选择企业性质",
            trigger: "change",
          },
        ],
        cylx: [
          {
            required: true,
            message: "请选择产业类型",
            trigger: "change",
          },
        ],
        qywz: [],
        gwjjfl: [],
        qycz: [],
        qyyx: [
          {
            message: "请输入正确的邮箱号",
            pattern:
              /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            trigger: "blur",
          },
        ],
        rzqd: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个融资渠道",
            trigger: "change",
          },
        ],
        qyyd: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个企业异动",
            trigger: "change",
          },
        ],
        zcdz: [
          {
            required: true,
            message: "请输入注册地址",
            trigger: "blur",
          },
        ],
        zbdz: [],
        bgly: [],
        xzqh1: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个企业归属地（园区）",
            trigger: "change",
          },
        ],
        xzqhdm: [
          {
            required: true,
            message: "请输入企业归属地（园区）代码",
            trigger: "blur",
          },
        ],
        sspq: [
          {
            required: true,
            message: "请选择所属片区",
            trigger: "change",
          },
        ],
        ssjd: [
          {
            required: true,
            message: "请选择所属街道",
            trigger: "change",
          },
        ],
        bgmj: [],
        zlyfmj: [],
        ggyfmj: [],
        zjyfmj: [],
        zkyxrs: [],
        ssjysrs: [],
        bsrs: [],
        bkrs: [],
        zkrs: [],
        // jsrs: [
        //     {
        //         required: true,
        //         message: "请输入研发/技术人数(人)",
        //         trigger: "blur",
        //     },
        // ],
        // zczrs: [
        //     {
        //         required: true,
        //         message: "请输入职工总人数(人)",
        //         trigger: "blur",
        //     },
        // ],
        fzjgsl: [
          {
            required: true,
            message: "请输入分支机构数量",
            trigger: "blur",
          },
        ],
        hwfzjgsl: [],
        hwyxjgsl: [],
        sfjldzz: [
          {
            required: true,
            message: "是否建立党组织不能为空",
            trigger: "change",
          },
        ],
        fmzls: [],
        ssqk: [],
        ssbk: [],
        ssdm: [],
        jyfw: [],
        qyjj: [],
      },
      cylxOptions: [],
      djztOptions: [],
      gwjjflOptions: [
        {
          id: 1,
          value: 1,
          label: "选项1",
        },
        {
          id: 1,
          value: 1,
          label: "选项1",
        },
      ],
      rzqdOptions: [],
      qyydOptions: [],
      xzqh1Options: [],
      sspqOptions: [
        {
          label: "梅林彩田片区",
          value: 1,
        },
        {
          label: "梅林美景",
          value: 2,
        },
      ],
      ssjdOptions: [
        {
          label: "梅林街道",
          value: 1,
        },
        {
          label: "梅景街道",
          value: 2,
        },
      ],
      sfjldzzOptions: [],
      ssqkOptions: [],
      ssbkOptions: [],
      gwjjflProps: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      xzqh1Props: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
        checkStrictly: true,
      },
      qyzclxList: [], //企业注册规模
      hyleList: [], //行业类别
      zcyqList: [], //注册要求
      cyleList: [], //产业类别
      nsdList: [], //纳税地
      qysxList: [], //企业属性
      qygmList: [], //企业规模
      czzqList: [], //成长周期
    };
  },
  computed: {
    ...mapGetters({
      userBaseInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
    }),
  },
  methods: {
    /**
     * 多个请求字典列表
     */
    getAllList() {
      this.getClassificationNationalEconomy(); // 获取国名经济分类
      this.getIndustryType(); // 获取产业类型
      this.getRegistrationStatus(); // 获取登记状态
      this.getFinancingChannels(); // 获取融资渠道
      this.getEnterpriseMobility(); // 获取企业异动
      this.getIsOrga(); // 是否建立党组织
      this.getStockStatus(); // 上市情况
      this.getStockMarket(); // 上市板块
      this.getqhdj(); // 获取区划等级
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 登记状态
     */
    getRegistrationStatus() {
      this.getClassWrap("sys_reg_status", "获取登记状态").then((res) => {
        this.djztOptions = res;
      });
    },
    /**
     * 获取产业类型
     */
    getIndustryType() {
      this.getClassWrap("sys_enter_type", "获取获取产业类型").then((res) => {
        this.cylxOptions = res;
      });
    },
    /**
     * 获取国名经济分类
     */
    getClassificationNationalEconomy() {
      this.getClassWrap("sys_category", "获取国名经济分类").then((res) => {
        this.gwjjflOptions = res;
      });
    },
    /**
     * 融资渠道
     */
    getFinancingChannels() {
      this.getClassWrap("sys_finance_channel", "获取融资渠道").then((res) => {
        console.log("lzqd>>>", res);
        this.rzqdOptions = res;
      });
    },
    /**
     * 企业异动
     */
    getEnterpriseMobility() {
      this.getClassWrap("sys_enter_unusual", "企业异动").then((res) => {
        this.qyydOptions = res;
      });
    },
    /**
     * 是否建立党组织
     */
    getIsOrga() {
      this.getClassWrap("sys_build_party_orga", "是否建立党组织").then(
        (res) => {
          this.sfjldzzOptions = res;
        }
      );
    },
    /**
     * 上市情况
     */
    getStockStatus() {
      this.getClassWrap("sys_stock_status", "上市情况").then((res) => {
        this.ssqkOptions = res;
      });
    },
    /**
     * 上市板块
     */
    getStockMarket() {
      this.getClassWrap("sys_stock_market", "上市板块").then((res) => {
        this.ssbkOptions = res;
      });
    },
    /**
     * 获取区划等级
     */
    getqhdj() {
      this.$httpApi
        .get("/dev-api/cfginfo/region/cascaderTreeList", {})
        .then((res) => {
          console.log("区划等级>>>", res);
          if (res.code == 200) {
            let resData = res.rows;
            this.xzqh1Options = JSON.parse(JSON.stringify(resData));
          }
        })
        .catch((err) => {});
    },
    /**
     * 对  "1001, 1002"  <---> ["1001", "1002"] 格式的转换
     *
     * @param { String }    情况1：传入的是 "1001, 1002" 此时应该是后台传的格式
     * @param { Array }     情况2：传入的是 ["1001", "1002"] 此时是 组件 选择后的，传给后台需要转成字符串
     *
     * @returns { Arrar , String }  依据情况转成对应的格式
     */
    transformSA(el) {
      let target;
      if (Array.isArray(el)) {
        // 如果是数组
        target = el.toString();
      } else if (typeof el === "string") {
        //  如果是字符串
        target = el.split(",");
      } else {
        console.log("非法传参");
        target = [];
      }
      // console.log("最后输出值>>>", target)
      return target;
    },
    /**
     * 提交 和 重置 按钮
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        // console.log("提交按钮>>>")
        if (!valid) return;
        // TODO 提交表单
        this.addEnterpriseBase();
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
      // console.log("重置按钮>>>")
    },
    /**
     * 删除企业用户基本信息
     */
    deleteEnterpriseBase() {},
    /**
     * 新增企业用户基本信息 and 修改外网企业用户基本信息
     */
    addEnterpriseBase() {
      let url =
        this.addUpStatus == 0
          ? "/dev-api/enterprise/baseInfo/install"
          : "/dev-api/enterprise/baseInfo/update";
      let params = JSON.parse(JSON.stringify(this.formData));
      params["logoMogoId"] = JSON.stringify(this.form);
      let _that = this;
      // console.log("状态>>>", this.addUpStatus, url)
      /* 对数据['1001', '1002'] 格式进行清洗
       */
      let stringList = ["rzqd", "qyyd", "xzqh1"];
      let xzqh2;
      let xzqh3;
      for (const i in params) {
        for (const item of stringList) {
          if (i.indexOf(item) !== -1) {
            params[i] = _that.transformSA(params[i]);
            // console.log(">>>>", i)
          }
        }
      }
      let areaids = params["xzqh1"].split(",");
      if (!areaids[1]) {
        //只选择市
        params["xzqh1"] = "510600000000";
        params["xzqh2"] = "510600000000";
        params["xzqh3"] = "510600000000";
        params["xzqhdm"] = "510600000000";
      }
      if (areaids[1] && !areaids[2]) {
        //选择到区
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[1];
        params["xzqh3"] = areaids[1];
        params["xzqhdm"] = areaids[1];
      }
      if (areaids[2] && !areaids[3]) {
        //选择到街道
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[2];
        params["xzqh3"] = areaids[2];
        params["xzqhdm"] = areaids[2];
      }
      if (areaids[3] && !areaids[4]) {
        //选择到居委会
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[2];
        params["xzqh3"] = areaids[3];
        params["xzqhdm"] = areaids[3];
      }
      /**
       * 20210419
       * gwjjfl国民经济分类，这个字段接收String，但是表单中用了cascader，会是一个Array
       * 手动转一下
       */
      if (typeof params.gwjjfl !== "string") {
        params.gwjjfl = params.gwjjfl === null ? "" : params.gwjjfl.toString();
      }
      /**
       * 20210526
       * 一些字段需要预处理一下
       */
      // Int类型的字段，只能传Int  //'jsrs',   // 'zczrs',
      let intList = [
        "bgmj",
        "bkrs",
        "bsrs",
        "fmzls",
        "fzjgsl",
        "ggyfmj",
        "hwfzjgsl",
        "hwyxjgsl",
        "ssjysrs",
        "zczb",
        "zjyfmj",
        "zkrs",
        "zkyxrs",
        "zlyfmj",
      ];
      for (let i = 0; i < intList.length; i++) {
        params[intList[i]] = Number(params[intList[i]] || 0);
      }
      // 注册资本 写死 人民币
      params.zczbdw = "129";
      if (this.addUpStatus == 1) {
        delete params.createTime;
        delete params.updateTime;
      }
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          // console.log("新增企业用户基本信息列表>>>", res)
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
            // 如果是登录之后弹框
            if(this.isfromLogin){
              this.$emit('closeDialog',false)
            }
            if (sessionStorage.getItem("openYh") == "true") {
              this.$confirm("是否返回系统推荐?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  sessionStorage.setItem("openYh", "false");
                  this.$router.push({
                    name: "policyMatchReport",
                  });
                })
                .catch(() => {});
            } else {
              // 企业用户基本信息列表
              this.getEnterpriseList();
            }
          } else if (res.code === 500) {
            this.$message({
              message: `${res.msg}`,
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 企业用户基本信息列表
     */
    getEnterpriseList() {
      let url = "/dev-api/enterprise/baseInfo/list";
      let params = {
        pageSize: 10, // 页大小
        pageNum: 1, // 页码
        // webEid: this.formData.webEid,
        shxydm: this.userBaseInfo.tyshxydm,
      };
      let _that = this;
      this.formData.shxydm = this.userBaseInfo.tyshxydm;
      this.formData.name = this.userBaseInfo.enterpriseList[0].enterprise;
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("企业用户基本信息列表>>>", JSON.stringify(res));
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            let resLen = resList.length; // 是否含有数据？
            this.addUpStatus = resLen !== 0 ? "1" : "0"; // 1 是修改 0 是新增
            // console.log("获取列表后的状态 ", resLen ,this.addUpStatus)
            /* 清晰数据： ！！！这里其实还可以对清洗封装，传入 1. 需要清洗的对象 2. 需要匹配的数组 */
            /* 对数据'1001, 1002' 格式进行清洗*/
            let stringList = ["rzqd", "qyyd"];
            let target = resList[0];
            //   target['rzqd']=["1000", "1003"]
            //  target['qyyd']=["in_normal"]
            /**
             * 20210625
             * 初始值为'null'的，修正为''
            //  */
            for (const [k, v] of Object.entries(target)) {
              if (v == null) {
                target[k] = "";
              }
            }
            for (const i in target) {
              for (const item of stringList) {
                if (i.indexOf(item) !== -1) {
                  target[i] = _that.transformSA(target[i]);
                  // console.log(">>>>", i)
                }
              }
            }
            //首次登录的用户没有填写政策推送相关信息
            if (res.rows[0].logoMogoId) {
              let tempForm = res.rows[0].logoMogoId;
              let tempForm1 = res.rows[0];
              if (tempForm === null) {
                Object.keys(tempForm1).map((key) => {
                  if (key === "logoMogoId") {
                    delete tempForm1.logoMogoId;
                    // tempForm = JSON.stringify(tempForm1);
                    // res.rows[0].logoMogoId已经是json格式，不需要再转换为json格式了
                    tempForm = tempForm1;
                  }
                });
              }
              let target1 = JSON.parse(tempForm);
              // 初始值为null修正为''
              for (const [k, v] of Object.entries(target1)) {
                if (v == null) {
                  target1[k] = "";
                }
              }
              _that.form = target1;
            }
            //市级区划回显
            if (Number(target.xzqh1) == "510600000000") {
              target.xzqh1 = [510600000000];
            }
            //区级区划回显
            else if (
              Number(target.xzqh1) == Number(target.xzqh2) &&
              Number(target.xzqh2) == Number(target.xzqh3)
            ) {
              target.xzqh1 = [510600000000, Number(target.xzqh1)];
            }
            //街道级区划回显
            else if (
              Number(target.xzqh1) != Number(target.xzqh2) &&
              Number(target.xzqh2) == Number(target.xzqh3)
            ) {
              target.xzqh1 = [
                510600000000,
                Number(target.xzqh1),
                Number(target.xzqh2),
              ];
            }
            //居委会级区划回显
            else {
              target.xzqh1 = [
                510600000000,
                Number(target.xzqh1),
                Number(target.xzqh2),
                Number(target.xzqh3),
              ];
            }
            console.log("列表清洗后的数据>>>", target);
            _that.$store.commit("updateAccountId", target.id);
            _that.formData = target;
          }
        })
        .catch((err) => {});
    },
    /**
     * 修改外网企业用户基本信息
     */
    updateEnterpriseBase() {
      // let url = '/dev-api/enterprise/baseInfo/update'
      // let params =  this.formData
      // params = JSON.stringify(params)
      // console.log("提交参数>>>", params)
      // this.$httpApi.postByBody(url, params).then((res) => {
      //     console.log("修改外网企业用户基本信息>>>", res)
      // })
    },
    /**
     * 企业用户基本信息详情
     */
    getEnterpriseBase() {
      let url = "/dev-api/enterprise/baseInfo/" + params;
      let params = {
        id: "chengzjjljle",
      };
      this.$httpApi
        .get(url, {})
        .then((res) => {
          // console.log("get企业用户基本信息>>>", res)
        })
        .catch((err) => {});
    },
    getlist(urlid) {
      let that = this;
      if (urlid) {
        that.$httpApi
          .get(`/dev-api/system/dict/getDictListByType?dictType=${urlid}`, {})
          .then((res) => {
            if (res.code == 200) {
              switch (urlid) {
                case "sys_category":
                  that.hyleList = res.data.filter((item) => {
                    return item.label != "不限";
                  });
                  break;
                case "sys_enter_type":
                  that.cyleList = res.data;
                  break;
                case "sys_enterprise_registertype":
                  that.qyzclxList = res.data;
                  break;
                case "enterprise_attributes":
                  that.qysxList = res.data.filter((item) => {
                    return item.label != "不限";
                  });
                  break;
                case "enterprise_size":
                  // 筛选掉不限选项
                  let temp = res.data.filter((item) => {
                    return item.key != "6";
                  });
                  that.qygmList = temp;
                  break;
                case "growth_cycle":
                  that.czzqList = res.data;
                  break;
                default:
                  break;
              }
            } else {
              return [];
            }
          })
          .catch((err) => {});
      }
    },
    // 获取行业类别
    gethyleList() {
      let urlid = "sys_category";
      this.getlist(urlid);
    },
    // 获取产业类别
    getcyleList() {
      let urlid = "sys_enter_type";
      this.getlist(urlid);
    },
    // 获取企业注册类型
    getqyzclxList() {
      let urlid = "sys_enterprise_registertype";
      this.getlist(urlid);
    },
    // 获取企业属性
    getqysxList() {
      let urlid = "enterprise_attributes";
      this.getlist(urlid);
    },
    // 获取企业规模
    getqygmList() {
      let urlid = "enterprise_size";
      this.getlist(urlid);
    },
    // 获取成长周期
    getczzqList() {
      let urlid = "growth_cycle";
      this.getlist(urlid);
    },
    init() {
      this.gethyleList();
      this.getcyleList();
      this.getqyzclxList();
      this.getqysxList();
      this.getqygmList();
      this.getczzqList();
    },
  },
  created() {
    this.getAllList();
    this.getEnterpriseList(); // 企业用户基本信息列表
    this.init();
    this.formData.webEid = this.userBaseInfo.id;
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sys_regType", // 企业性质
      ],
    });
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.b-fieldset {
  // border: 1px solid #dfe4ed;
  border: 1px solid #03b1ff;
  border-radius: 5px;
  padding: 10px 20px 0px 0px;
  margin-bottom: 15px;
}

.b-fieldset-legend {
  text-align: center;
  font-weight: 700;
  color: #03b1ff;
  padding: 0 15px;
}
</style>
